<template>
  <div class="invoiceDetail">
    <CommonDetail type="invoice"/>
  </div>
</template>

<script>
import CommonDetail from "@components/CommonDetail";
export default {
  name: "invoiceDetail",

  components: {
    CommonDetail,
  },

};
</script>

<style lang="less" scoped></style>
