import { render, staticRenderFns } from "./InvoiceDetail.vue?vue&type=template&id=16b2e226&scoped=true"
import script from "./InvoiceDetail.vue?vue&type=script&lang=js"
export * from "./InvoiceDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16b2e226",
  null
  
)

export default component.exports